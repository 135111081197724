@import "nlib/config";

.editDocument {
  display: flex;
  position: absolute;
  z-index: 19;
  top: 0;
  left: 0;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $uiWhiteColor;
  .form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .content {
    display: flex;
    flex: 1;
    overflow: auto;
    .documentCol {
      position: relative;
      flex: auto;
      min-width: 23rem;
      .pdfViewer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $uiBackgroundColor;
      }
    }
    .formCol {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding-left: $contentDefaultIndent / 4;
      .colHeader {
        padding: 0 $contentDefaultIndent * 2 $contentDefaultIndent * 2;
        .title {
          margin-bottom: $contentDefaultIndent;
          font-size: 1.25rem;
          font-weight: 500;
        }
      }
    }
  }
}
