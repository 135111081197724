@import "nlib/config";

.tableHeader {
  display: flex;
  flex: none;
  align-items: center;
  padding: $contentDefaultIndent / 2 $contentDefaultIndent $contentDefaultIndent / 2 $contentDefaultIndent * 2.5;
  overflow-y: scroll;
  .title {
    margin-right: $contentDefaultIndent;
    font-size: 0.875rem;
    font-weight: 500;
  }
  .addLineItemButton {
    margin-left: auto;
  }
  .button {
    margin-right: $contentDefaultIndent;
    svg {
      font-size: 1rem;
    }
  }
  .errors {
    display: flex;
    align-items: center;
    padding: $contentDefaultIndent / 2 $contentDefaultIndent;
    border-radius: $contentDefaultIndent / 2;
    background-color: lighten(mix($uiAccentColor, $uiWarningColor), 36%);
    color: lighten(mix($uiAccentColor, $uiBlackColor), 15%);
    font-weight: 500;
    svg {
      font-size: 1rem;
    }
  }
}
