.container {
  display: flex;
  flex: auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.documentLabeler {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
