@import "nlib/config";

.bulkActions {
  position: relative;
  position: sticky;
  z-index: 4;
  top: $contentDefaultIndent * 2;
  width: 100%;
  margin-top: $contentDefaultIndent * 2;
  border: 1px solid $uiPrimaryColor;
  &[disabled] {
    opacity: 1 !important;
    > * {
      opacity: 0.5 !important;
    }
  }
  .text {
    margin-right: $contentDefaultIndent * 2;
    font-size: 0.9375rem;
    white-space: nowrap;
  }
  .input {
    flex: auto;
    max-width: 20rem;
    margin-right: $contentDefaultIndent * 2;
  }
  .buttons {
    display: flex;
    margin-left: auto;
    padding-left: $contentDefaultIndent * 3;
    > * {
      margin-left: $contentDefaultIndent;
    }
  }
  .entityContainer {
    display: flex;
    flex: auto;
    max-width: 400px;
    .selectEntityMode {
      min-width: 110px;
      button {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      + .input {
        input,
        button {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}
