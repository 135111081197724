@import "nlib/config";

.lineItemsContainer {
  display: flex;
  z-index: 1;
  flex-direction: column;
  max-height: 30%;
  overflow: hidden;
  border-top: 1px solid $uiBorderColor;
  background: $uiWhiteColor;
  .container {
    padding: 0 $contentDefaultIndent * 1.5;
    overflow-y: scroll;
  }
  .table {
    display: flex;
    flex: auto 1;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    padding-bottom: 1rem;
    background-color: $uiWhiteColor;
    font-size: 0.8125rem;
  }
  .cell {
    display: flex;
    flex: 1;
    align-items: center;
    overflow: hidden;
    border-top: 1px solid $uiBorderColor;
    border-right: 1px solid $uiBorderColor;
    &:first-child {
      border-left: 1px solid $uiBorderColor;
    }
  }
  .row {
    display: flex;
    &:last-child {
      border-bottom: 1px solid $uiBorderColor;
    }
  }
  .head {
    position: sticky;
    z-index: 1;
    top: 0;
    .row {
      &:last-child {
        border-bottom: none;
      }
    }
    .cell {
      min-height: 2rem;
      background: linear-gradient(#fff, #f9f8f9);
      color: $uiDarkColor;
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1rem;
      user-select: none;
      > div {
        display: flex;
        padding: 0 $contentDefaultIndent;
      }
    }
  }
  .cellNumber {
    flex: none;
    justify-content: start;
    width: 64px;
    padding: 0 $contentDefaultIndent;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background-color: #f9f8f9;
    > div {
      padding: 0 !important;
    }
    .checkAll {
      display: flex;
    }
  }
  .cellCheckBox {
    justify-content: center;
    background-color: #f9f8f9;
  }
  .cellDescription {
    flex: 90;
    width: 90px;
  }
  .cellQuantity {
    flex: 35;
    width: 35px;
  }
  .cellAmount {
    flex: 45;
    width: 45px;
  }
  .cellVat {
    flex: 40;
    width: 40px;
  }
  .cellTaxRate {
    flex: 50;
    width: 50px;
  }
  .removeCell {
    flex: none;
    width: 32px;
  }
  .cellClass {
    flex: 70;
    width: 70px;
  }
  .cellProjects {
    flex: 70;
    width: 70px;
  }
  .cellItems {
    flex: 70;
    width: 70px;
  }
  .cellCategory {
    flex: 70;
    width: 70px;
    &.extended {
      flex: 90;
      width: 90px;
    }
  }
  .noData {
    padding: $contentDefaultIndent * 2 $contentDefaultIndent;
    color: $uiPlaceholderColor;
    font-size: 1rem;
    text-align: center;
  }
}
.checkAllDropDownContent {
  z-index: 1050;
}
