@import "nlib/config";

.statusFilter {
  .tab {
    display: flex;
    position: relative;
    align-items: center;
    .icon {
      margin-right: 0.3em;
    }
    .label {
      display: block;
      margin-left: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .counter {
      min-width: 21px;
    }
    .mobile {
      display: none;
    }
    .icon,
    .label,
    .counter {
      pointer-events: none;
    }
  }
  &.simplifyLayout {
    &.toManyTabs {
      width: 100%;
      .tab {
        .icon {
          display: none;
        }
      }
    }
  }
}

@media (max-width: $viewportSizeMedium) {
  [data-mobile] {
    .statusFilter {
      &.toManyTabs {
        flex: auto;
        .tab {
          flex: 1;
          flex-direction: column;
          justify-content: center;
          min-width: 3rem;
          max-width: 6rem;
          padding-right: $contentDefaultIndent / 2;
          padding-left: $contentDefaultIndent / 2;
          svg {
            margin-right: 0;
            font-size: 1.5rem;
          }
          span {
            margin: 0;
          }
          .label {
            width: 100%;
            overflow: hidden;
            font-size: 0.75rem;
            text-align: center;
            text-overflow: ellipsis;
          }
          .counter {
            display: none;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 50%;
            margin-left: 0;
          }
          .mobile {
            display: initial;
          }
        }
      }
    }
  }
}

@media (max-width: $viewportSizeExtraSmall) {
  [data-mobile] {
    .statusFilter {
      &.toManyTabs {
        .tab {
          padding-right: $contentDefaultIndent / 4;
          padding-left: $contentDefaultIndent / 4;
          svg {
            font-size: 1.25rem;
          }
          .label {
            font-size: 9px;
          }
          .counter {
            font-size: 0.625rem;
          }
        }
      }
    }
  }
}
