@import "nlib/config";

.previewCell {
  font-size: $uiDesktopFontSize;
  .documentPreview {
    width: 2.84em;
    min-width: 2.84em;
    height: 4em;
  }
}
