@import "nlib/config";

.categoryOrItemCell {
  display: flex;
  padding: 0 !important;
  border-top: 1px solid $uiBorderColor;
  border-right: 1px solid $uiBorderColor;
  cursor: pointer;
  &:first-child {
    border-left: 1px solid $uiBorderColor;
  }
  &.editing {}
  &.frozen {
    cursor: default;
  }
  .content {
    display: flex;
    flex: auto;
    align-items: center;
    overflow: hidden;
    .value {
      display: flex;
      flex: auto;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 1px $contentDefaultIndent 0;
      overflow: hidden;
      .iconInvalid {
        margin-right: 0.3em;
        color: $uiNegativeColor;
        font-size: 1rem;
      }
      &.placeholder {
        color: $uiPlaceholderColor;
      }
      div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .input {
      flex: auto;
      input {
        height: 2rem;
        border: none;
        border-radius: 0;
        background: none;
        box-shadow: inset 0 0 0 1px $uiHighlightColor;
      }
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 2rem;
      height: 2rem;
      font-size: 1rem;
      cursor: pointer;
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
  &:not(.editing) {
    &.invalid {
      .value {
        transition: box-shadow 0.2s;
        background-color: lighten(mix($uiAccentColor, $uiWarningColor), 37%) !important;
        &.placeholder {
          color: #e7b5a0;
        }
      }
    }
  }
}

.select {
  flex: 1;
  min-width: 90px;
  max-width: 30%;
  button {
    height: 2rem !important;
    padding: 0 0.5rem;
    border: none;
    border-radius: 0;
    :global(.caret) {
      padding-left: 4px;
    }
  }
}

body {
  .dropDownClassName {
    width: auto;
  }
}
