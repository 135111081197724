@import "nlib/config";

.activityTab {
  display: flex;
  position: relative;
  flex: auto;
  flex-direction: column;
  padding: $contentDefaultIndent * 2;
  overflow: auto;
}
