@import "nlib/config";

.commentCell {
  margin: 0 -6px;
  &.businessUser {
    max-width: 100%;
  }
  &:not(.businessUser) {
    .toggle {
      padding: 6px 6px;
      border-radius: $uiBorderRadius;
      &:hover {
        background-color: $uiBorderColor;
        color: $uiBlackColor;
      }
    }
  }
  .tooltip {
    max-width: 100%;
  }
  .tooltipContent {
    position: relative;
    padding-left: 0.75rem;
    overflow: hidden;
    font-size: 0.8125rem;
    line-height: 1.25rem;
    text-overflow: ellipsis;
    word-break: break-all;
    &::after {
      content: "";
      position: absolute;
      top: 2px;
      bottom: 2px;
      left: 1px;
      width: 3px;
      border-radius: 3px;
      background-color: $uiWarningColor;
    }
    &.noComment {
      color: $uiMediumDarkColor;
      &::after {
        background-color: $uiMediumDarkColor;
      }
    }
  }
  .toggle {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    svg {
      font-size: 1.125rem;
    }
  }
  .dropdownContent {
    width: 22rem !important;
    max-height: none !important;
    overflow: visible;
    .commentsBlock {
      max-height: 20rem;
    }
  }
  .emptyState {
    text-align: center;
  }
  &.businessUser {
    .toggle {
      color: $uiPrimaryColor;
    }
  }
  &:not(.businessUser) {
    &.hasUnread {
      .toggle {
        svg {
          color: $uiPrimaryColor;
        }
      }
    }
  }
}
