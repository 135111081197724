.container {
  display: flex;
  .selectCategoryOrItem {
    min-width: 30%;
    max-width: 30%;
    button {
      height: 2rem !important;
      padding: 0 0.5rem;
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    + div {
      input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}
body {
  .dropDownClassName {
    width: auto;
  }
}
