@import "nlib/config";

.fieldsPanel {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 22.5rem;
  overflow: auto;
  background-color: $uiWhiteColor;
  .header {
    overflow-y: scroll;
    .links {
      display: flex;
      justify-content: space-between;
      padding: $contentDefaultIndent * 1.5;
      overflow: hidden;
      .downloadLink,
      .externalLinkContainer {
        overflow: hidden;
        text-align: right;
        a {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        svg {
          font-size: 1rem;
        }
      }
      .downloadLink {
        margin-right: auto;
        svg {
          color: $uiPositiveColor;
        }
      }
      .externalLinkContainer {
        min-width: fit-content;
        margin-left: $contentDefaultIndent * 2;
        svg {
          color: $uiAlternativeColor;
        }
      }
    }
    .tabs {
      .tab {
        flex: auto;
        padding-top: 0;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        &:hover {
          background-color: $uiWhiteColor;
          color: $uiHighlightColor;
        }
      }
    }
  }
  .fieldsContainer {
    flex: 1;
    overflow-y: scroll;
  }
}
