@import "nlib/config";

.tableContainer {
  display: flex;
  position: relative;
  flex: auto;
  flex-direction: column;
  .documentsTable {
    min-width: 260px;
    [data-table-cell] {
      padding-right: 0.6%;
      padding-left: 0.6%;
    }
    .tableRow {
      cursor: pointer;
    }
    .placeholderCell {
      flex: 1;
      max-width: 1px;
    }
    .checkboxCell {
      flex: none;
      width: 31px;
      padding-right: 0;
      padding-left: $contentDefaultIndent * 2;
    }
    .statusCell {
      flex: 4;
      justify-content: center;
      width: 4px;
      min-width: 54px;
    }
    .typeCell {
      flex: 4;
      width: 4px;
      min-width: 74px;
    }
    .documentIdCell {
      flex: 6;
      width: 6px;
      min-width: 70px;
    }
    .amountCell {
      flex: 8;
      justify-content: flex-end;
      width: 8px;
      min-width: 100px;
      max-width: 200px;
      font-size: 0.75rem;
      font-weight: 500;
    }
    .addressCell {
      flex: 10;
      width: 10px;
      min-width: 72px;
      svg {
        font-size: 1.125rem;
      }
      a {
        font-weight: 500;
        text-decoration: none;
        &[data-inactive] {
          font-weight: normal;
        }
      }
    }
    .issueDateCell {
      flex: 8;
      width: 8px;
      min-width: 80px;
      white-space: nowrap;
    }
    .descriptionCell {
      flex: 12;
      width: 12px;
      min-width: 60px;
    }
    .categoriesCell {
      flex: 9;
      width: 9px;
      min-width: 60px;
      &.withCategorySelector {
        flex: 12;
        width: 12px;
        min-width: 90px;
      }
    }
    .paymentAccountCell {
      flex: 9;
      justify-content: left;
      width: 9px;
      min-width: 50px;
    }
    .commentsCell {
      flex: 3;
      width: 3px;
      min-width: 60px;
      padding-right: 0;
    }
    .transactionsCell {
      flex: 3;
      width: 3px;
      min-width: 60px;
    }
    .uploadedByCell {
      flex: 3;
      justify-content: center;
      width: 3px;
      min-width: 44px;
    }
    .previewCell {
      flex: 3;
      justify-content: center;
      width: 3px;
      min-width: 49px;
      padding-top: $contentDefaultIndent / 2;
      padding-bottom: $contentDefaultIndent / 2;
    }
    .actionsCell {
      flex: none;
      width: 112px;
      padding-top: $contentDefaultIndent / 2;
      padding-bottom: $contentDefaultIndent / 2;
      padding-left: $contentDefaultIndent * 2;
    }
    &.businessUser {
      .commentsCell {
        flex: 8;
        width: 8px;
        min-width: 80px;
        text-align: initial;
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .pagination {
      margin-right: $contentDefaultIndent * 2;
    }
    .pagination,
    .pageSize {
      margin-top: $contentDefaultIndent * 2;
    }
  }
}
