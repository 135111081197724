@import "nlib/config";

.typeCell {
  max-width: 100%;
  .documentType {
    font-size: 0.625rem;
    line-height: 0.75rem;
    .badge {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
