@import "nlib/config";

.categoriesCell {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  .split {
    input {
      cursor: pointer;
    }
  }
  .mode {
    margin-left: $contentDefaultIndent / 2;
    color: $uiHighlightColor;
    font-size: 1rem;
  }
}
