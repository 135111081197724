@import "nlib/config";

.listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .name {
    margin-right: $contentDefaultIndent;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .badge {
    flex: none;
    max-width: 40%;
  }
  .indent {
    display: inline-block;
    width: 1rem;
  }
}
@media (max-width: 360px) {
  .listItem {
    .badge {
      display: none;
    }
  }
}
