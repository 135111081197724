@import "nlib/config";

.documentTab {
  display: flex;
  flex: auto;
  justify-content: space-around;
  overflow: hidden;
  overflow: auto;
  .leftCol,
  .rightCol {
    position: relative;
    flex: 1;
    width: 1px;
    padding: 0;
  }
  .leftCol {
    z-index: 1;
    flex: 1;
    .content {
      max-width: 32rem;
      margin: auto;
      padding: $contentDefaultIndent * 2;
    }
  }
  .rightCol {
    background-color: $uiBackgroundColor;
  }
  .row {
    display: flex;
    flex: auto;
    margin-right: $contentDefaultIndent * -1;
    margin-bottom: $contentDefaultIndent * 2;
    margin-left: $contentDefaultIndent * -1;
    .col {
      flex: 1;
      padding: 0 $contentDefaultIndent;
    }
    .colStreet {
      width: 70%;
    }
  }
  .label {
    display: flex;
    justify-content: space-between;
    margin-bottom: $contentDefaultIndent;
    font-size: 0.75rem;
    font-weight: 500;
  }
  .contactForm {
    background-color: $uiBackgroundColor;
    .wrapper {
      padding: $contentDefaultIndent * 2 $contentDefaultIndent * 2 0;
    }
    .footer {
      display: flex;
      position: sticky;
      bottom: 0;
      align-items: center;
      justify-content: center;
      padding: $contentDefaultIndent * 2;
      background-image: linear-gradient(transparent, $uiBackgroundColor $contentDefaultIndent * 2);
      .button {
        flex: 1;
        + .button {
          margin-left: $contentDefaultIndent * 2;
        }
      }
    }
  }
  .addressWrap {
    margin: $contentDefaultIndent * 1.5 $contentDefaultIndent * -2 0 $contentDefaultIndent * -1.5;
    padding: $contentDefaultIndent / 2 $contentDefaultIndent * 2 $contentDefaultIndent * 1.5 $contentDefaultIndent * 1.5;
    border-top-left-radius: $uiBorderRadius;
    border-bottom-left-radius: $uiBorderRadius;
    &.contactFormShown {
      background-color: $uiBackgroundColor;
    }
    .label {
      margin-top: 0;
    }
    .flex {
      display: flex;
    }
    .addressInput {
      flex: 1;
      input {
        position: relative;
        left: 1px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        &:focus {
          z-index: 1;
          + div {
            z-index: 1;
          }
        }
      }
    }
    .button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .matchingTransactions {
    justify-content: flex-start;
    margin-bottom: $contentDefaultIndent * 2;
    overflow: hidden;
    font-weight: normal;
    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .description {
    margin-bottom: $contentDefaultIndent;
    color: $uiDarkColor;
    font-size: 0.75rem;
  }
  .prefillButton {
    svg {
      font-size: 1rem;
    }
    span {
      flex: auto;
      padding-right: 1rem;
    }
  }
}
