@import "nlib/config";

.documentIdCell {
  max-width: 100%;
  .documentId {
    font-size: 0.8125rem;
    font-weight: 500;
    svg {
      font-size: 1.2em;
    }
    .warning {
      color: $uiWarningColor;
    }
    .negative {
      color: $uiNegativeColor;
    }
  }
}
