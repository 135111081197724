@import "nlib/config";

.documentsPopup {
  .card {
    width: 40rem;
    margin-top: $contentDefaultIndent * 2;
    font-weight: 500;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 1.5rem;
      height: 1.5rem;
      margin-right: $contentDefaultIndent * 1.5;
      border-radius: 50%;
      background-color: $uiBackgroundColor;
      box-shadow: 0 0 0 4px rgba($uiDarkColor, 0.2);
      font-size: 1rem;
    }
    .text {
      margin-right: $contentDefaultIndent;
    }
    .button {
      height: 2.5rem;
      margin-left: auto;
      border-color: $uiWhiteColor;
      background-color: transparent;
      color: $uiWhiteColor;
      font-size: 0.875rem;
      font-weight: 500;
      &:hover {
        background-color: rgba($uiWhiteColor, 0.1);
      }
    }
  }
  &.review {
    .card {
      background-color: $uiPrimaryColor;
      color: $uiWhiteColor;
      .icon {
        background-color: $uiWhiteColor;
        box-shadow: 0 0 0 4px rgba($uiWhiteColor, 0.2);
        color: $uiPrimaryColor;
      }
    }
  }
  &.approve {
    .card {
      background-color: $uiPositiveColor;
      color: $uiWhiteColor;
      .icon {
        background-color: $uiWhiteColor;
        box-shadow: 0 0 0 4px rgba($uiWhiteColor, 0.2);
        color: $uiPositiveColor;
      }
    }
  }
}

[data-mobile] {
  .documentsPopup {
    bottom: 86px;
    width: 100%;
    max-width: 32rem;
    padding-right: $contentDefaultIndent;
    padding-bottom: $contentDefaultIndent;
    padding-left: $contentDefaultIndent;
    .card {
      width: 100%;
      font-weight: normal;
      .button {
        width: 3rem;
        height: 3rem;
        svg {
          margin-right: 0;
          margin-left: 0;
          font-size: 1.125rem;
        }
      }
    }
  }
}
