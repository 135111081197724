.documentLabelerContent {
  display: flex;
  flex: 1;
  overflow: hidden;
  background-color: #efefef;
  .fieldsPanelContainer {
    display: flex;
    width: 22.5rem;
    overflow: hidden;
  }
  .documentWrapper {
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
  }
  .documentContainer {
    position: relative;
    flex: auto;
    width: 100%;
    overflow: auto;
  }
  .svgPath {
    position: absolute;
    top: 37px;
    left: 0;
  }
  .exitFullScreenButton {
    position: fixed;
    z-index: 1;
    right: 1.5rem;
    bottom: 1rem;
    width: 2rem;
    padding: 0;
    svg {
      font-size: 1.5rem;
    }
  }
}
