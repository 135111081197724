@import "nlib/config";

$animationDuration: 0.2s;

.documentPreview {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 2.3rem;
  min-width: 2.3rem;
  height: 3.25rem;
  overflow: hidden;
  border: 1px solid darken($uiBorderColor, 10%);
  border-radius: $uiBorderRadius;
  cursor: default;
  &:hover {
    border-color: $uiMediumDarkColor;
    .actions {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  &.clickable {
    cursor: pointer;
  }
  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .preview {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-transform: uppercase;
  }
  .actions {
    display: flex;
    position: absolute;
    bottom: $contentDefaultIndent / 2;
    left: 0;
    justify-content: space-evenly;
    width: 100%;
    transform: translate(0, 50%);
    transition: opacity $animationDuration, transform $animationDuration;
    opacity: 0;
    .edit,
    .download,
    .detach {
      padding: 0.3em;
      border-radius: 50%;
      background-color: rgba($uiBlackColor, 0.5);
      color: $uiWhiteColor;
      font-size: 0.75em;
    }
    .edit {
      &:hover {
        background-color: $uiPrimaryColor;
      }
    }
    .download {
      &:hover {
        background-color: $uiPositiveColor;
      }
    }
    .detach {
      &:hover {
        background-color: $uiNegativeColor;
      }
    }
  }
  .loader {
    z-index: initial;
    color: $uiNeutralColor;
    font-size: 1rem;
  }
  .error {
    color: $uiNegativeColor;
    font-size: 1.5rem;
  }
}
