@import "nlib/config";

.coloredBlock {
  position: absolute;
  border: 1px solid rgba($uiHighlightColor, 0.1);
  border-radius: 2px;
  scroll-margin: 5 * 8px;
  background-color: rgba($uiHighlightColor, 0.05);
  cursor: pointer;
}
