@import "nlib/config";

.transactionsCell {
  margin: 0 -6px;
  padding: 6px 6px;
  border-radius: $uiBorderRadius;
  color: inherit;
  font-weight: 500;
  &:hover {
    position: relative;
    z-index: 1;
    background-color: $uiBorderColor;
    color: $uiBlackColor;
  }
  > div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  svg {
    font-size: 1.125rem;
  }
}
