@import "nlib/config";

.item {
  svg {
    margin-right: 0.25rem;
    font-size: 1rem;
    &[data-status] {
      &[data-status=toExtract] {
        color: $uiWarningColor;
      }
      &[data-status=toReconcile] {
        color: $uiSpecialColor;
      }
      &[data-status=needReaction] {
        color: $uiWarningColor;
      }
      &[data-status=toReview] {
        color: $uiHighlightColor;
      }
      &[data-status=toReport] {
        color: $uiPositiveColor;
      }
      &[data-status=exported] {
        color: $uiMediumDarkColor;
      }
      &[data-status=excluded] {
        color: $uiAccentColor;
      }
    }
  }
}
