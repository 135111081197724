@import "nlib/config";

.documentsTable {
  [data-table-cell] {
    padding: $contentDefaultIndent;
  }
  svg {
    font-size: 1rem;
  }
  .tableRow {
    min-height: 38px;
    background: none;
    &.warning {
      background-color: lighten($uiWarningColor, 38%);
      font-weight: 500;
    }
  }
  .statusCell {
    flex: 5;
    justify-content: center;
    width: 5px;
    svg {
      font-size: 1.25rem;
    }
    [data-status] {
      &[data-status=toExtract] {
        svg {
          color: $uiWarningColor;
        }
      }
      &[data-status=toReconcile] {
        svg {
          color: $uiSpecialColor;
        }
      }
      &[data-status=needReaction] {
        svg {
          color: $uiWarningColor;
        }
      }
      &[data-status=toReview] {
        svg {
          color: $uiHighlightColor;
        }
      }
      &[data-status=toReport] {
        svg {
          color: $uiPositiveColor;
        }
      }
      &[data-status=exported] {
        svg {
          color: $uiMediumDarkColor;
        }
      }
      &[data-status=excluded] {
        svg {
          color: $uiAccentColor;
        }
      }
      &[data-status=error] {
        svg {
          color: $uiNegativeColor;
        }
      }
    }
  }
  .idCell {
    flex: 10;
    width: 10px;
    a {
      display: block;
      overflow: hidden;
      color: inherit;
      font-weight: 500;
      text-overflow: ellipsis;
      white-space: nowrap;
      .textNegative {
        color: $uiNegativeColor;
      }
      &:hover {
        color: $uiPrimaryColor;
        text-decoration: none;
      }
    }
  }
  .amountCell {
    flex: 12;
    justify-content: flex-end;
    width: 12px;
    padding-right: $contentDefaultIndent * 2;
    .amount {
      overflow: hidden;
      color: $uiMediumDarkColor;
      font-size: $uiDesktopFontSize;
      font-weight: 500;
      text-overflow: ellipsis;
      white-space: nowrap;
      &.coloredNumber {
        color: $uiPositiveColor;
        &[data-negative] {
          color: $uiNegativeColor;
        }
      }
    }
  }
  .addressCell {
    flex: 14;
    width: 14px;
    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .issueDateCell {
    flex: 9;
    width: 9px;
  }
  .lineItemsCell {
    flex: 16;
    width: 16px;
    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .actionsCell {
    flex: none;
    width: 86px;
    padding-right: 0;
    &.tableBodyCell {
      padding-top: $contentDefaultIndent / 2;
      padding-bottom: $contentDefaultIndent / 2;
    }
  }
}
