@import "nlib/config";

.sidebar {
  text-align: center;
  .switcher {
    margin-bottom: $contentDefaultIndent * 1.5;
    button {
      flex: auto;
      padding: 0;
    }
  }
  .dropArea {
    height: 7rem;
    margin-bottom: $contentDefaultIndent * 2;
  }
  .cloudDocumentsPickers {
    margin-bottom: $contentDefaultIndent;
    .title {
      margin-bottom: $contentDefaultIndent;
      text-align: left;
    }
    .pickers {
      display: flex;
      > div {
        flex: 1;
        width: 1px;
        + div {
          margin-left: $contentDefaultIndent;
        }
        button {
          width: 100%;
        }
      }
    }
  }
  .autoUploadEmailGroup {
    margin-top: $contentDefaultIndent;
    margin-bottom: $contentDefaultIndent * 2;
    line-height: 1.4;
    text-align: left;
    .email {
      display: flex;
      align-items: center;
      margin-top: $contentDefaultIndent / 2;
      overflow: hidden;
      font-weight: bold;
      white-space: nowrap;
      cursor: pointer;
      svg {
        font-size: 1rem;
      }
      &:hover {
        svg {
          color: $uiPrimaryColor;
        }
      }
      > span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      > svg {
        font-size: 1.2em;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: center;
    margin-top: $contentDefaultIndent * 2;
    button {
      padding-right: $contentDefaultIndent * 4;
      padding-left: $contentDefaultIndent * 4;
      + button {
        margin-left: $contentDefaultIndent * 2;
      }
    }
  }
  .footerContent {
    width: 100%;
    .mergeCheckbox {
      display: flex;
      margin-bottom: $contentDefaultIndent * 2;
      button {
        width: 100%;
      }
    }
    .buttons {
      display: flex;
    }
  }
}
[data-mobile] .sidebar {
  .cloudDocumentsPickers {
    button {
      justify-content: flex-start;
      min-width: 8.5rem;
      padding-right: $contentDefaultIndent;
      padding-left: $contentDefaultIndent;
      span {
        flex: auto;
      }
    }
  }
  .autoUploadEmailGroup {
    .email {
      font-size: inherit;
      word-break: break-all;
    }
  }
}
