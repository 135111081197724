@import "nlib/config";

.duplicatedDocumentsWindow {
  .dialogClassName {
    width: 50rem;
    .content {
      position: relative;
      min-height: 14rem;
      max-height: 21rem;
      overflow: auto;
      .message {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $contentDefaultIndent;
        font-weight: 500;
        line-height: 3rem;
        svg {
          color: $uiWarningColor;
          font-size: 1rem;
        }
      }
    }
  }
}
