@import "nlib/config";

@mixin counterBadgeMixin($name, $color) {
  $animationName: #{$name}Pulse;
  $shadowColorA: rgba($uiWhiteColor, 0.85);
  $shadowColorB: rgba($color, 0.7);
  @keyframes #{$animationName} {
    0% {
      box-shadow: 0 0 0 0 #{$shadowColorA}, 0 0 0 0 #{$shadowColorB};
    }
    70% {
      box-shadow: 0 0 0 2px #{$shadowColorA}, 0 0 0 6px #{$shadowColorB};
    }
    100% {
      box-shadow: 0 0 0 0 #{$shadowColorA}, 0 0 0 0 #{$shadowColorB};
    }
  }
  background-color: $color;
  &.animated {
    animation: #{$animationName} 0.4s 2;
  }
}

.counter {
  margin-left: 0.375rem;
  &.withValue {
    border-color: transparent;
    color: $uiWhiteColor;
    &[data-status=toExtract] {
      @include counterBadgeMixin("warning", $uiWarningColor);
    }
    &[data-status=toReconcile] {
      @include counterBadgeMixin("special", $uiSpecialColor);
    }
    &[data-status=toReview] {
      @include counterBadgeMixin("highlight", $uiHighlightColor);
    }
    &[data-status=toReport] {
      @include counterBadgeMixin("positive", $uiPositiveColor);
    }
    &[data-status=exported] {
      @include counterBadgeMixin("dark", $uiMediumDarkColor);
    }
    &[data-status=excluded] {
      @include counterBadgeMixin("accent", $uiAccentColor);
    }
    &[data-status=needReaction] {
      @include counterBadgeMixin("warning", $uiWarningColor);
    }
    &[data-status=inProgress] {
      @include counterBadgeMixin("dark", $uiLightBlueColor);
    }
    &:not([data-status]) {
      background-color: $uiBlackColor;
    }
  }
}
