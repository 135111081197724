@import "nlib/config";
@import "animations";

.headerButtons {
  display: flex;
  button + button {
    margin-left: $contentDefaultIndent;
  }
  [data-loading] svg {
    animation: rotate 2s linear infinite;
  }
}
