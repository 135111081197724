@import "nlib/config";

$buttonDefaultBgColor: #e9ecef;

.documentControlPanel {
  display: flex;
  z-index: 1;
  align-items: center;
  padding: $contentDefaultIndent / 2;
  border-right: 1px solid $uiBorderColor;
  border-bottom: 1px solid $uiBorderColor;
  background: $uiWhiteColor;
  button {
    width: 1.75rem;
    height: 1.75rem;
    padding: 0;
    color: $uiDarkColor;
    svg {
      font-size: 1.25rem;
    }
  }
  .left,
  .center,
  .right {
    display: flex;
    flex: 1;
    align-items: center;
  }
  .left {
    button {
      margin-right: 0.3em;
    }
    input {
      width: 2.5rem;
      margin-right: 0.3em;
      padding: 0 6px;
      border: 1px solid $uiBorderColor;
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      ~ button {
        margin-right: 0;
        margin-left: 0.3em;
      }
    }
  }
  .center {
    justify-content: center;
    .select {
      width: 3.6rem;
      height: 1.75rem;
      padding: 0 0.125rem;
      border: 1px solid transparent;
      border-radius: 0.25rem;
      background-color: $uiWhiteColor;
      text-align: center;
      cursor: pointer;
    }
  }
  .right {
    justify-content: flex-end;
  }
}
