.documentRenderer {
  position: relative;
  &.grabbable {
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
  .document {
    position: relative;
    width: fit-content;
    margin: auto;
    margin-top: 8px;
  }
  :global(.react) {
    &-pdf__Page {
      margin-bottom: 8px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    }
  }
}
