@import "nlib/config";

.bulkActions {
  display: flex;
  flex: auto;
  align-items: center;
  margin-right: $contentDefaultIndent;
  white-space: nowrap;
  input {
    height: 2rem;
  }
  .control {
    flex: 1;
    max-width: 20rem;
    margin-right: $contentDefaultIndent;
  }
  .button {
    margin-right: $contentDefaultIndent;
    svg {
      font-size: 1rem;
    }
  }
}
.dropDownClassName {
  z-index: 1050;
}
