@import "nlib/config";

.actionsCell {
  position: relative;
  width: 100%;
  text-align: center;
  .tooltip {
    width: 14rem;
  }
  .button {
    height: 1.5rem;
    padding-right: $contentDefaultIndent / 2 !important;
    padding-left: $contentDefaultIndent / 2 !important;
    background-color: transparent;
  }
  svg {
    min-width: 1rem;
    font-size: 1rem;
    + span {
      margin-left: 0.3em;
    }
    &.colorSuccess {
      color: $uiPositiveColor;
    }
  }
  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
