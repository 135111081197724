@import "nlib/config";

.statusCell {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  svg {
    font-size: 1.5rem;
  }
  .badge {
    position: absolute;
    top: 50%;
    min-width: 29px;
    height: auto;
    margin: 0;
    padding: $contentDefaultIndent / 4 $contentDefaultIndent / 2;
    transform: translate(0, 4px);
    border-radius: $contentDefaultIndent;
    line-height: 1;
    text-align: center;
    &.confidence {
      font-weight: bold;
    }
    &:not(.confidence) {
      background-color: $uiWhiteColor;
      text-transform: lowercase;
    }
  }
  &.withBadge {
    margin-top: -0.8em;
  }
  &.toExtract {
    color: $uiWarningColor;
  }
  &.toReconcile {
    color: $uiSpecialColor;
  }
  &.needReaction {
    color: $uiWarningColor;
  }
  &.toReview {
    color: $uiHighlightColor;
  }
  &.toReport {
    color: $uiPositiveColor;
  }
  &.exported {
    color: $uiMediumDarkColor;
  }
  &.excluded {
    color: $uiAccentColor;
  }
  &.error {
    color: $uiNegativeColor;
  }
}
