.deleteCell {
  padding: 0 !important;
  .iconButton {
    width: 2rem;
    padding: 0;
    border: none;
  }
  [disabled] {
    opacity: 0.1 !important;
  }
}
