.dropBoxPicker {
  height: 2.5rem;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
  img {
    height: 1.6em;
    margin-right: 0.3em;
  }
}
