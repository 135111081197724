@import "nlib/config";

.header {
  display: flex;
  align-items: center;
  height: 4rem;
  padding: 0 $contentDefaultIndent * 2;
  .info {
    margin-right: auto;
    overflow: hidden;
    .title {
      display: flex;
      margin-right: $contentDefaultIndent * 2;
      font-size: 1rem;
      font-weight: 500;
      white-space: nowrap;
      .documentId {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      svg {
        font-size: 1.5rem;
        &.warning {
          color: $uiWarningColor;
        }
      }
      .muted {
        opacity: 0.75;
      }
    }
    .subtitle {
      overflow: hidden;
      color: $uiDarkColor;
      font-size: 0.75rem;
      line-height: 1rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .switchButtons {
    display: flex;
    align-items: center;
    .counter {
      margin: 0 $contentDefaultIndent * 2;
    }
  }
  .actions {
    display: flex;
    button {
      min-width: 4rem;
    }
    .warning {
      svg {
        color: $uiWarningColor;
      }
    }
  }
  .download {
    margin-left: $contentDefaultIndent * 2;
  }
  button {
    margin-left: $contentDefaultIndent;
  }
  .closeButton {
    svg {
      color: $uiNegativeColor;
    }
  }
}
